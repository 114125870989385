<template>
  <div style="text-align: center;">
    <van-list>
      <van-image
        style="margin: 25% 35% 3% 35%;"
        width="5rem"
        height="5rem"
        fit="cover"
        :src="newImg"
      />
      <span style="margin: auto 35% auto 35%;">支付成功</span>
      <van-button
        type="primary"
        style="margin: 15% 5% auto 5% ; width: 90%; height: 40%;"
        color="#57c4b7"
        round
        size="large"
        @click="onEnter"
      >确定</van-button>
    </van-list>
  </div>
</template>
<script>
// import { Toast } from "vant";
// import ajax from "../../lib/ajax";
// const OperatorId = "000000";
export default {
  data() {
    return {
      newImg: require("../../assets/PaySuccess.png")
    };
  },
//   created() {
//     this.onload();
//   },
  methods: {
    // async onload() {
    //   let orderId = sessionStorage.getItem("orderid");
    //   let regNo = sessionStorage.getItem("regNo");
    //   let seqNo = sessionStorage.getItem("seqNos");
    //   // let seqNo = "23132,45888";
    //   let deptName = sessionStorage.getItem("deptName");
    //   let drName = sessionStorage.getItem("drName");
    //   let operTime = sessionStorage.getItem("operTime");
    // //   let openId = sessionStorage.getItem("openId");
    // //   let userName = sessionStorage.getItem("userName");
    // //   let sumfee = sessionStorage.getItem("sumfee");
    // //   let itemName = sessionStorage.getItem("itemName");
    //   let seqNos = [];
    //   seqNos = seqNo.split(",");
    //   Toast.loading({
    //     message: "支付中...",
    //     forbidClick: true,
    //     loadingType: "spinner",
    //     duration: 0
    //   });
    //   await ajax
    //     .post("/Api/WechatAgentService/AgentFeePaid", {
    //       orderId: orderId,
    //       regNo: regNo,
    //       seqNos: seqNos,
    //       termialType: null,
    //       payWay: 3,
    //       cardPayData: null,
    //       hisFromDept: deptName,
    //       hisFromDoctor: drName,
    //       hisFromCreateTime: operTime,
    //       operatorId: OperatorId,
    //       machineName: "wechat"
    //     })
    //     .then(res => {
    //       if (res.data.result === 0) {
    //         Toast.clear();
    //         Toast("缴费成功，请注意查看缴费情况");
    //       } else if (res.data.result === -1) {
    //         Toast.clear();
    //         Toast("缴费失败，订单不存在");
    //       } else {
    //         Toast.clear();
    //         Toast("缴费失败，出现未知错误：" + res.data.result);
    //       }
    //     })
    //     .catch(err => {
    //       Toast.clear();
    //       Toast("出现未知错误：" + err);
    //     });
      // 异步更新订单状态
      // let time = 21;
      // let timer = setInterval(() => {
      //     setTimeout(() => {
      //         if (time > 0) {
      //             ajax.get("/Api/PayService/QueryOrderPayState?input="+orderId)
      //             .then((res) => {
      //                 type = res.data.result.state;
      //                 // let orderNo = res.data.result.platformOrderNo;
      //                 // alert(JSON.stringify(type));
      //                 // 数据全部加载完成
      //                 if (type === 2) {
      //                     time = 0;
      //                     Toast.clear();
      //                     // 提交门诊缴费
      //                     ajax.post("/Api/FeeService/SubmitPay",{
      //                         orderId:orderId,
      //                         regNo:regNo,
      //                         seqNos:seqNos,
      //                         termialType:null,
      //                         payWay:3,
      //                         hisFromDept:deptName,
      //                         hisFromDoctor:drName,
      //                         hisFromCreateTime:operTime,
      //                         machineName:'wechat',
      //                         operatorId:OperatorId,
      //                         cardPayData:null
      //                     }).then((res) => {
      //                         let rep = res.data.result;
      //                         if (rep.success) {
      //                             ajax.post("/Api/DummyAppService/FeeSuccess",{
      //                                 openId:openId,
      //                                 userName:userName,
      //                                 orderNo:orderId,
      //                                 cost:sumfee,
      //                                 itemName:itemName
      //                             });
      //                         }else {
      //                             Toast("提交失败"+rep.msg);
      //                         }
      //                     }).catch((err) => {
      //                         Toast("错误："+err.data.result.msg);
      //                     });
      //                 }
      //             }).catch((err) => {
      //                 console.log(err);
      //             });
      //             --time;
      //         }else{
      //             clearTimeout(timer);
      //             Toast.clear();
      //             Toast("此次交易已超时，款项将退回");
      //             ajax.get("/Api/PayService/OnQueryOrderStateTimeOut?input="+orderId)
      //             .then(res=>{
      //                 if (res.data.result===0||res.data.result===1) {
      //                     Toast("系统已为您申请超时退款，请注意查收！");
      //                 }else {
      //                     Toast("申请超时退款失败，请到窗口详询");
      //                 }
      //             })
      //         }
      //     }, 0);
      // }, 3000);
    // },
    onEnter() {
      this.$router
        .replace({
          path: "/FCardL"
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>